const PersonIcon = (props) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00016 9.33334C9.92314 9.33334 10.8254 9.05964 11.5928 8.54686C12.3603 8.03408 12.9584 7.30525 13.3116 6.45253C13.6648 5.5998 13.7572 4.66149 13.5772 3.75625C13.3971 2.851 12.9526 2.01948 12.3 1.36684C11.6474 0.714192 10.8158 0.269735 9.91059 0.0896708C9.00534 -0.0903936 8.06703 0.00202197 7.21431 0.355231C6.36159 0.70844 5.63275 1.30658 5.11997 2.07401C4.60719 2.84144 4.3335 3.74369 4.3335 4.66667C4.3335 5.90435 4.82516 7.09133 5.70033 7.9665C6.5755 8.84167 7.76249 9.33334 9.00016 9.33334ZM9.00016 1.33334C9.65944 1.33334 10.3039 1.52883 10.8521 1.8951C11.4002 2.26137 11.8275 2.78197 12.0798 3.39106C12.3321 4.00014 12.3981 4.67037 12.2694 5.31697C12.1408 5.96357 11.8234 6.55752 11.3572 7.02369C10.891 7.48987 10.2971 7.80734 9.65047 7.93595C9.00386 8.06457 8.33364 7.99856 7.72455 7.74627C7.11547 7.49398 6.59487 7.06673 6.2286 6.51857C5.86233 5.97041 5.66683 5.32594 5.66683 4.66667C5.66683 3.78261 6.01802 2.93477 6.64314 2.30965C7.26826 1.68452 8.11611 1.33334 9.00016 1.33334Z"
        fill={props.color}
      />
      <path
        d="M17.3135 14.2467C16.2444 13.1167 14.956 12.2166 13.5272 11.6015C12.0984 10.9863 10.5591 10.6691 9.0035 10.6691C7.44789 10.6691 5.90863 10.9863 4.4798 11.6015C3.05098 12.2166 1.76263 13.1167 0.693498 14.2467C0.461706 14.4942 0.332971 14.8209 0.333498 15.16V18.6667C0.333498 19.0203 0.473973 19.3594 0.724022 19.6095C0.97407 19.8595 1.31321 20 1.66683 20H16.3335C16.6871 20 17.0263 19.8595 17.2763 19.6095C17.5264 19.3594 17.6668 19.0203 17.6668 18.6667V15.16C17.6692 14.8218 17.5429 14.4953 17.3135 14.2467ZM16.3335 18.6667H1.66683V15.1533C2.61165 14.1585 3.74902 13.3662 5.00974 12.8249C6.27045 12.2835 7.62813 12.0043 9.00016 12.0043C10.3722 12.0043 11.7299 12.2835 12.9906 12.8249C14.2513 13.3662 15.3887 14.1585 16.3335 15.1533V18.6667Z"
        fill={props.color}
      />
    </svg>
  );
};

export default PersonIcon;
