import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography, Box, useTheme } from "@mui/material";
import { getTranslation } from "../../../utils/helper";
import { useTranslation } from "react-i18next";
import Styles from './CategoriesBar.module.css';
import { useEffect, useState } from "react";

const CategoriesBar = ({selectedCategory,hasOffers,items,onOffersSelect}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const params = useParams();
    const [categoryId, setCategoryId] = useState(params?.id || selectedCategory);
    const [ categories , setCategories ] = useState(items || []);

    useEffect(() => {
        if (params?.id !== 'offers') {
            setCategoryId(parseInt(params?.id) || selectedCategory);
        } else {
            setCategoryId('offers');
            onOffersSelect();
        };

    }, [params?.id,selectedCategory]);

    useEffect(() => {
        const filteredItems = items.filter(item => item.id !== parseInt(params?.id));
        const currentItem = items.find(item=>item.id  === parseInt(params?.id));
        filteredItems.unshift(currentItem);
        setCategories(filteredItems);

        if (!params?.id && !params?.id === 'offers') {
            setCategoryId(items[0]['id']);
        };

    }, []);

    return (
        <Box>
            {categories.length > 0 && (
                <Swiper
                    pagination={{ clickable: true }}
                    className={`${Styles.CategoriesBar}`}
                    spaceBetween={10}
                    slidesPerView={"auto"}
                >
                    {hasOffers && <SwiperSlide 
                    virtualIndex={'offers'}
                    className={Styles.CatergoriesBar__item}>
                        <Link to={`/categories/offers`} className={Styles.CatergoriesBar__itemBox} style={{ backgroundColor: (categoryId === 'offers') ? theme.palette.primary.main : '#eee' }}>
                            <Typography
                                variant="subtitle1"
                                component="h6"
                                fontWeight="500"
                                fontSize={16}
                                lineHeight={1.3}
                                color={(categoryId === 'offers') ? '#fff' : '#777'}
                                className={Styles["CatergoriesBar__item--title"]}
                            >
                                {t('Offers')}
                            </Typography>
                        </Link>
                    </SwiperSlide>}
                    {categories.map((item, i) => {
                        return (
                            <SwiperSlide virtualIndex={item?.category} key={i} className={Styles.CatergoriesBar__item}>
                                <Link className={Styles.CatergoriesBar__itemBox} to={`/categories/${item?.id}`} style={{ backgroundColor: (categoryId === item?.id) ? theme.palette.primary.main : '#eee' }}>
                                    <Typography
                                        variant="subtitle1"
                                        component="h6"
                                        fontWeight="500"
                                        fontSize={16}
                                        lineHeight={1.3}
                                        color={(categoryId === item?.id) ? '#fff' : '#777'}
                                        className={Styles["CatergoriesBar__item--title"]}>
                                        {getTranslation(item, i18n.resolvedLanguage)?.name}
                                    </Typography>
                                </Link>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )
            }
        </Box >
    );
};

export default CategoriesBar;
