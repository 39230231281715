import {
  Box
} from "@mui/material";
import { useLocation } from "react-router-dom";

import GoogleMapsLocation from '../../components/google_maps/googleMaps';
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { useTranslation } from "react-i18next";
import BranchChangeDialog from "../../components/BranchChangeDialog/BranchChangeDialog";

const LocationPage = () => {
const { t } = useTranslation();
  const {state} = useLocation();
  const { city } = state; 

  return <Box className="LocationPage" sx={{height:'100%'}}>
    <CustomAppBar hasBack title={t('Delivery Location')}/>
   <GoogleMapsLocation city={city}  />
  
  </Box>
};

export default LocationPage;