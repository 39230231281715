import { Card,  Box, Typography,ButtonBase,useTheme,Alert} from "@mui/material";
import { useTranslation } from "react-i18next";
import IncIcon from '../../../components/Icons/IncreaseIcon';
import DecreaseIcon from '../../../components/Icons/DecreaseIcon';

const ExtrasSelectorQuantity = ({ extras, meta ,hidePrice, extrasTotalQty, onIncrement, onDecrement }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    return (
        <Card elevation={0} sx={{ mx: '15px', my: '5px', padding: '15px' }}><Box sx={{ position: 'relative', zIndex: 5 }}>
        <Typography variant="h6" component="h5" sx={{ mb: '10px' }}>{meta?.category_name}</Typography>


        {(meta && meta.maximum >= 0 && meta.minimum >= 0 && i18n.resolvedLanguage === 'en') && <Alert severity="info" sx={{ marginBottom: '15px' }}> Minimum quantity {meta.minimum} and maximum {meta.maximum}</Alert>}
        {(meta && meta.maximum >= 0 && meta.minimum >= 0 && i18n.resolvedLanguage === 'ar') && <Alert severity="info" sx={{ marginBottom: '15px' }}> قم باختيار {meta.minimum} كحد ادني و {meta.maximum} كحد اقصي</Alert>}

        {extras?.map((extra, i) => {
            // console.log(extra);
            return (<Box key={i} >
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                    <Typography>{extra.extra_name} {hidePrice === 0 ? <strong>({extra.extra_price} {t('EGP')})</strong> : null}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: i18n.resolvedLanguage === 'en' ? 'row' : 'row-reverse', alignItems: 'center', mt: '10px' }}>
                        <ButtonBase disabled={extra.qty === 0} onClick={() => onDecrement(extra)} ><DecreaseIcon color={theme.palette.primary.main} /></ButtonBase>
                        <Box sx={{ px: '10px' }}><Typography sx={{ textAlign: 'center' }}>{extra.qty}</Typography></Box>
                        <ButtonBase style={{ opacity: (meta.maximum <= extrasTotalQty) ? 0.2 : 1 }} disabled={meta.maximum <= extrasTotalQty} onClick={() => onIncrement(extra)}><IncIcon color={theme.palette.primary.main} /></ButtonBase>
                    </Box>
                </Box>
            </Box>)
        })}
        <Box sx={{ mb: '30px' }} />
        {(meta.maximum <= extrasTotalQty) && <Alert severity="warning" sx={{ marginBottom: '15px' }}>{t('You have reached the maximum qunatity')}</Alert>}

    </Box></Card>
    )
};

export default ExtrasSelectorQuantity;