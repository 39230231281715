import React, { useState } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography, Box, IconButton ,Snackbar } from "@mui/material";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import OrderPlacedImage from '../../assets/images/orderPlaced.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from "react-i18next";
import moment from "moment";
import copy from 'copy-to-clipboard';

const CheckoutOrderPage = () => {
    const { state } = useLocation();
    const { order } = state;
    const { t, i18n } = useTranslation();
    const [clipboardSnakeBarOpened, setClipboardSnakeBarOpened] = useState(false);

    const navigate = useNavigate();

    const copyToClipBoardHandler = () => {
        copy(order.inv_num);
        setClipboardSnakeBarOpened(true);
    };
    
    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={()=>setClipboardSnakeBarOpened(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    return (
        <Box id="CheckoutRegisterPage" sx={{ height: '100%' }}>
            <CustomAppBar title={t("Receipt")} />
            <Box sx={{ height: 'calc(100% - 100px)', p: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', flex: 1 }}>
                    <img src={OrderPlacedImage} style={{ width: '250px', maxWidth: '100%', alignSelf: 'center' }} />
                    <Typography variant="h6" color="#172327" sx={{ mt: '15px', width: '60%', alignSelf: 'center', mb: '30px' }} lineHeight="1.4" fontSize="28px" textAlign="center" component="h2">{t('Thank you for ordering')}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography variant="h6" sx={{ mb: '10px' }}>{t('Order Information')}</Typography>
                            <Typography variant="body1" sx={{ mb: '10px' }}>{t('Order number')} : {order.inv_num}</Typography>
                            <Typography variant="body1">{t('Time and Date')} : {moment(new Date(order.created_at)).locale(i18n.resolvedLanguage).format('DD-MM-YYYY,  hh:mm A')}</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={copyToClipBoardHandler}><ContentCopyIcon /></IconButton>
                        </Box>
                    </Box>
                </Box>



                <Box sx={{ mt: '20px' }}>
                    <Button disableElevation sx={{ width: '100%', mb: '16px' }} size="large" variant="contained" component={Link} to="/">{t('Back to home')}</Button>
                    {/* <Button disableElevation sx={{ width: '100%' }} size="large" variant="outlined" component={Link} to={"/track-order/"+order.id}>{t('Track your order')}</Button> */}
                </Box>
            </Box>
            <Snackbar
                open={clipboardSnakeBarOpened}
                autoHideDuration={6000}
                onClose={()=>setClipboardSnakeBarOpened(false)}
                message={t("Order Num Copied to Clipboard")}
                action={action}
            />
        </Box>
    )
};

export default CheckoutOrderPage;