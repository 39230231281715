
import { ListItem, ListItemText, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import axiosInstance from '../../../config/axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import validator from 'validator';

const InputDialog = ({ onClose, onChange, label, defaultValue, value, isOpen, type, onOpen, initialObject, Updatekey, onSubmit }) => {
    const { t } = useTranslation();
    const [ inputValue , setInputValue ] = useState();

    const handleUpdate = async () => {

        let data = {};

        try {

            switch (Updatekey) {
                case 'name':
                    data = { ...initialObject, 'name': value };
                    break;
                case 'email':
                    if(validator.isEmail(value)){
                        data = { ...initialObject, 'email': value };
                    };
                    
                    break;
                case 'address':
                    data = { ...initialObject, 'address': value };
                    break;
                case 'city':
                    data = { ...initialObject, 'city': value };
                    break;
            };


            await axiosInstance.post('/customer/info/update', data);
            onSubmit();


        } catch (error) {
            console.log('profileUpdate error', error);
        }

    }

    return (<>
        <ListItem
            secondaryAction={
                <Button edge="end" onClick={() => onOpen()} >
                    {t('Change')}
                </Button>
            }
        >
            <ListItemText primary={t(label)} secondary={defaultValue} />
        </ListItem>
        <Dialog open={isOpen} onClose={() => onClose(false)}>
            <DialogTitle>{t(label)}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    defaultValue={defaultValue||''}
                    type={type || 'text'}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        if(defaultValue !== e.target.value) {
                            onChange(e.target.value);
                        };
                     
                    }}
                />
                { inputValue === '' ? <Typography sx={{fontSize:'12px'}} color={"red"} component='small'>{t('This Field is required')}</Typography> : null}
                { Updatekey === 'email' && inputValue !== defaultValue && inputValue?.length > 0 && !validator.isEmail(inputValue) ? <Typography sx={{fontSize:'12px'}} color={"red"} component='small'>{t('Invalid entry')}</Typography> : null}
               
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>{t('Cancel')}</Button>
                { Updatekey === 'email' ? <Button onClick={handleUpdate} disabled={!inputValue ||  inputValue === defaultValue || ( inputValue && (inputValue === '' || !validator.isEmail(inputValue)))}>{t('Update')}</Button> : null }
                { Updatekey !== 'email' ? <Button onClick={handleUpdate} disabled={!inputValue || inputValue === defaultValue || ( inputValue && inputValue === '')}>{t('Update')}</Button> : null }

            </DialogActions>
        </Dialog>
    </>

    )
}
;

export default InputDialog;