import { useContext, lazy, Suspense } from "react";
import AuthContext from "../store/auth-context";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from '../pages/auth/login/LoginPage';
import HomePage from '../pages/Home/HomePage';
import LocationPage from '../pages/location/LocationPage';
import WelcomePage from '../pages/welcome/WelcomePage';
import CartPage from '../pages/Cart/CartPage';
import MenuPage from '../pages/Menu/MenuPage';
import OrdersPage from '../pages/Orders/OrdersPage';
import CategoriesPage from '../pages/Categories/CategoriesPage';
import ProductDetailsPage from '../pages/ProductDetail/ProductDetailsPage';
import DeliveryOptionsPage from '../pages/DeliveryOptions/DeliveryOptionsPage';
import ProfilePage from '../pages/Profile/ProfilePage';
import CheckoutRegisterPage from '../pages/CheckoutRegister/CheckoutRegisterPage';
import CheckoutVerifyPage from '../pages/CheckoutVerify/CheckoutVerifyPage';
import TrackOrderPage from '../pages/TrackOrder/TrackOrderPage';
import OrderDetailsPage from '../pages/OrderDetails/OrderDetailsPage';
import CheckoutConfirmPage from '../pages/CheckoutConfirm/CheckoutConfirmPage';
import CheckoutOrderPage from '../pages/CheckoutOrder/CheckoutOrderPage';
import OrderReviewPage from '../pages/OrderReview/OrderReviewPage';
import RegisterPage from '../pages/auth/Register/Register';
import VerifyPage from '../pages/auth/Verify/Verify';
import ConfirmPhonePage from '../pages/auth/ConfirmPhone/ConfirmPhone';
import { ThemeOptionsContext } from "../theme/ThemeProvider";
import CheckoutContext from "../store/checkout-context";
import SuspenseLoader from "../components/SuspenseLoader/SuspenseLoader";
import NotFoundPage from "../pages/NotFound/NotFound";

const AboutPage = lazy(() => import('../pages/About/AboutPage'));
const NotificationsPage = lazy(() => import('../pages/Notifications/NotificationsPage'));
const ContactPage = lazy(() => import('../pages/Contact/ContactPage'));
const SafariLocationHelper = lazy(() => import('../pages/SafariLocationHelper/SafariLocationHelper'));
const ForgotPasswordPage = lazy(() => import('../pages/auth/ForgotPassword/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('../pages/auth/ResetPassword/ResetPassword'));
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicyPage'));
const TermsUsePage = lazy(() => import('../pages/TermsUse/TermsUsePage'));



const RoutesWrapper = () => {
    const authCtx = useContext(AuthContext);
    const appearanceCtx = useContext(ThemeOptionsContext);
    const checkoutCtx = useContext(CheckoutContext);
    return <>
        <Suspense fallback={<SuspenseLoader/>}>
            <Routes>
                <Route path='/' element={authCtx.isLoggedIn || appearanceCtx.appearance.enable_intro === "0" ? <Navigate to="/products" /> : <WelcomePage />} />
                <Route path='*' element={<NotFoundPage/>} />
                <Route path='/products' element={<HomePage />} />
                <Route path="/forgot-password" element={authCtx.isLoggedIn ? <Navigate to="/" /> : <ForgotPasswordPage />} />
                <Route path="/reset-password" element={authCtx.isLoggedIn ? <Navigate to="/" /> : <ResetPasswordPage />} />
                <Route path='/login' element={authCtx.isLoggedIn ? <Navigate to="/" /> : <LoginPage />} />
                <Route path="/register" element={authCtx.isLoggedIn ? <Navigate to="/" /> : <RegisterPage />} />
                <Route path='/verify' element={authCtx.isLoggedIn ? <Navigate to="/" /> : <VerifyPage />} />
                <Route path='/confirm-phone' element={authCtx.isLoggedIn ? <Navigate to="/" /> : <ConfirmPhonePage />} />
                <Route path='/delivery' element={<DeliveryOptionsPage />} />
                <Route path='/location' element={<LocationPage />} />
                <Route path="/location-helper" element={<SafariLocationHelper />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/account" element={<MenuPage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/orders/:id" element={!authCtx.isLoggedIn ? <Navigate to="/orders" /> : <OrderDetailsPage />} />
                <Route path="/track-order/:id" element={!authCtx.isLoggedIn ? <Navigate to="/orders" /> : <TrackOrderPage />} />
                <Route path="/review-order/:id" element={!authCtx.isLoggedIn ? <Navigate to="/orders" /> : <OrderReviewPage />} />
                <Route path="/categories/:id?" element={<CategoriesPage />} />
                <Route path="/products/:id" element={<ProductDetailsPage />} />
                <Route path="/profile" element={!authCtx.isLoggedIn ? <Navigate to={-1} /> : <ProfilePage />} />
                <Route path="/checkout-register" element={<CheckoutRegisterPage />} />
                <Route path="/checkout-verify" element={!authCtx.isLoggedIn ? <Navigate to="/products" /> : <CheckoutVerifyPage />} />
                <Route path="/checkout-confirm" element={!authCtx.isLoggedIn ? <Navigate to="/products" /> : <>{!checkoutCtx.deliveryType ? <DeliveryOptionsPage /> : <CheckoutConfirmPage />}</>} />
                <Route path="/checkout-order-placed" element={!authCtx.isLoggedIn ? <Navigate to="/products" /> : <CheckoutOrderPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/contacts" element={<ContactPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/terms-use" element={<TermsUsePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>
        </Suspense>
    </>
};

export default RoutesWrapper;