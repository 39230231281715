const CartIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 15.875C7.5 16.1222 7.42669 16.3639 7.28934 16.5695C7.15199 16.775 6.95676 16.9352 6.72835 17.0299C6.49995 17.1245 6.24861 17.1492 6.00614 17.101C5.76366 17.0527 5.54093 16.9337 5.36612 16.7589C5.1913 16.5841 5.07225 16.3613 5.02402 16.1189C4.97579 15.8764 5.00054 15.6251 5.09515 15.3966C5.18976 15.1682 5.34998 14.973 5.55554 14.8357C5.7611 14.6983 6.00277 14.625 6.25 14.625C6.58152 14.625 6.89946 14.7567 7.13388 14.9911C7.3683 15.2255 7.5 15.5435 7.5 15.875ZM14.375 14.625C14.1278 14.625 13.8861 14.6983 13.6805 14.8357C13.475 14.973 13.3148 15.1682 13.2201 15.3966C13.1255 15.6251 13.1008 15.8764 13.149 16.1189C13.1972 16.3613 13.3163 16.5841 13.4911 16.7589C13.6659 16.9337 13.8887 17.0527 14.1311 17.101C14.3736 17.1492 14.6249 17.1245 14.8534 17.0299C15.0818 16.9352 15.277 16.775 15.4143 16.5695C15.5517 16.3639 15.625 16.1222 15.625 15.875C15.625 15.5435 15.4933 15.2255 15.2589 14.9911C15.0245 14.7567 14.7065 14.625 14.375 14.625ZM17.9219 4.79687L15.8594 12.0156C15.7461 12.407 15.509 12.751 15.1836 12.9961C14.8582 13.2412 14.4621 13.3742 14.0547 13.375H6.57031C6.16293 13.3742 5.76681 13.2412 5.44141 12.9961C5.116 12.751 4.87888 12.407 4.76562 12.0156L2.70312 4.80469V4.78906L1.9375 2.125H0.625C0.45924 2.125 0.300269 2.05915 0.183058 1.94194C0.065848 1.82473 0 1.66576 0 1.5C0 1.33424 0.065848 1.17527 0.183058 1.05806C0.300269 0.940848 0.45924 0.875 0.625 0.875H1.9375C2.20898 0.875984 2.47286 0.96478 2.68971 1.12812C2.90656 1.29146 3.06475 1.52058 3.14062 1.78125L3.77344 4H17.3203C17.4171 3.9999 17.5126 4.02229 17.5993 4.0654C17.686 4.10851 17.7615 4.17117 17.8198 4.24844C17.8781 4.3257 17.9177 4.41546 17.9354 4.51063C17.9531 4.60581 17.9485 4.7038 17.9219 4.79687ZM16.4922 5.25H4.13281L5.96875 11.6719C6.00612 11.8025 6.08507 11.9175 6.19362 11.9992C6.30217 12.081 6.43441 12.1251 6.57031 12.125H14.0547C14.1906 12.1251 14.3228 12.081 14.4314 11.9992C14.5399 11.9175 14.6189 11.8025 14.6562 11.6719L16.4922 5.25Z"
        fill={props.color}
      />
    </svg>
  );
};

export default CartIcon;
