import React, { useContext, useEffect } from 'react';
import './App.css';

import { useNavigate } from 'react-router-dom';
import PWAPrompt from 'react-ios-pwa-prompt'
import { CircularProgress, Snackbar, Button, IconButton } from '@mui/material';
import InfoContext from './store/info-context';
import { ThemeOptionsContext } from './theme/ThemeProvider';
import AuthContext from './store/auth-context';
import CheckoutContext from './store/checkout-context';
import CartContext from './store/cart-context';
import CustomerContext from './store/customer-context';
import { useTranslation } from 'react-i18next';
import axiosInstance from './config/axios';
import moment from 'moment';
import ClosedPage from './pages/Closed/Closed';
import RoutesWrapper from './Routes/Routes';
import Error from './components/Error/Error';
import AppOuter from './components/AppOuter/AppOuter';
import { requestForToken, onMessageListener } from './config/firebase';
import useInfo from './hooks/useInfo';

const queryParams = new URLSearchParams(window.location.search);
const paramToken = queryParams.get("token");
const paramBranchId = queryParams.get("branch_id");
const paramBranchName = queryParams.get("branch_name");
const paramSection = queryParams.get("section");
const paramTable = queryParams.get("table");
const paramOrderType = queryParams.get("order_type");



function App() {
  const infoQuery = useInfo();

  const navigate = useNavigate();
  const infoCtx = useContext(InfoContext);
  
  const appearanceCtx = useContext(ThemeOptionsContext);
  const authCtx = useContext(AuthContext);
  const checkoutCtx = useContext(CheckoutContext);
  const cartCtx = useContext(CartContext);
  const { t, i18n } = useTranslation();
  const customerCtx = useContext(CustomerContext);

  useEffect(() => {

    if (paramOrderType) {
      checkoutCtx.setDeliveryType(paramOrderType);
    };

    if (paramSection && paramTable) {
      checkoutCtx.setDineInOption(paramSection, paramTable);
    }

    if (paramBranchId && paramBranchName) {
      authCtx.saveBranch({ id: paramBranchId, name: paramBranchName });
    }

  }, []);


  useEffect(() => {
    requestForToken().then((token) => {
      if (authCtx.token) {
        axiosInstance.post(`/fcm-token`, { fcm_token: token }, {
          headers: { Authorization: `Bearer ${authCtx.token}` }
        }).then((res) => {
          // console.log('fcm res',res);
        }).catch(err => {
          // console.log('fcm err', err);
        });
      }
    });
  }, [authCtx.token]);


  onMessageListener().catch((err) => console.log('failed: ', err));

  const validateToken = () => {
    if (paramToken) {
      authCtx.validate(paramToken);
      customerCtx.fetch();
    };
  }

  useEffect(() => {
    validateToken();
  }, [paramToken]);

  useEffect(() => {
    if (i18n.resolvedLanguage === 'ar') {
      require('moment/locale/ar');
      moment.locale('ar')
    } else {
      moment.locale('en');
    }
  }, []);

  const action = (
    <React.Fragment>
      <Button color="inherit" size="small" onClick={() => {
        navigate('/cart');
        cartCtx.notifHide();
      }}>
        {t('View Cart')}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => cartCtx.notifHide()}
      >
        {/* <CloseIcon fontSize="small" /> */}
      </IconButton>
    </React.Fragment>
  );

  return (
    <div id='app' style={!infoQuery.data? { flexDirection:'column',justifyContent:'center',alignItems:'center' }: null}>

     { !infoCtx.info && <Error title="404" subtitle="Restaurant not found" />}

      <div className={`preloader ${infoQuery.isLoading | appearanceCtx.loadingAppearance ? 'preloader-active' : ''}`}>
        <CircularProgress color="inherit" />
      </div>
   
      {infoQuery.data && infoQuery.data?.temp_closed === 1 ? <ClosedPage /> : ''}
      {infoQuery.data && infoQuery.data?.temp_closed === 0 ? <>
        <div id="app__wrap">
          <div id="app__inner">
           <RoutesWrapper/>
          </div>

          <Snackbar
            open={cartCtx.notifActive}
            color='primary'
            autoHideDuration={10000}
            onClose={() => { cartCtx.notifHide() }}
            message={t('Item Added to Cart')}
            action={action}
            className='success-snackbar'
          />

        </div>
      <AppOuter/>
        <PWAPrompt />
      </> : ''}
    </div>
  );
}

export default App;
