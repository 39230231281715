import { Loader } from '@googlemaps/js-api-loader';
import React, { useEffect,  useState, useContext } from 'react';
import SearchIcon from '../../assets/images/searchIcon.png';
import LocationIcon from '../../assets/images/LocationIcon.png';
import { useNavigate, Link } from 'react-router-dom';
import Styles from './googleMap.module.css';
import { Box, FormControl, InputLabel, Input, InputAdornment, Typography, Button, IconButton } from '@mui/material';
import axiosConfig from '../../config/axios';
import config from '../../config/config';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../store/auth-context';
import CheckoutContext from '../../store/checkout-context';
import { Close } from '@mui/icons-material';
import i18nLang from '../../config/i18n';
import BranchChangeDialog from '../BranchChangeDialog/BranchChangeDialog';
import CartContext from '../../store/cart-context';

var startLatLng;


// this api key belong to gromenu.webmaster@gmail.com > gromenu

const loader = new Loader({
  apiKey: "AIzaSyAcEiNQt8__D82ojg8R6TL-4HQ1t7B-8TA",
  version: "weekly",
  libraries: ['drawing', 'geometry', 'places', 'visualization'],
  language: i18nLang.resolvedLanguage
});

const GoogleMapsLocation = (props) => {
  const { i18n, t } = useTranslation();
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const [userLocation, setUserLocation] = useState();
  const [showField, setShowField] = useState(false);
  const [branchesMarkers, setBranchesMarkers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [address, setAddress] = useState();
  const [geoCodedaddress, setGeoCodedAddress] = useState();
  const [deliveryFees, setDeliveryFees] = useState();
  const checkoutCtx = useContext(CheckoutContext);
  const addressEl = document.getElementById('address');
  const mapEl = document.getElementById('map');
  const navigate = useNavigate();
  const [branchChangeDialogOpened, setBranchChangeDialogOpen] = useState(false);

  let inZone = [];


  const fetchBranches = async () => {

    try {
      const google = await loader.load();
      const branchesRes = await axiosConfig.get(`/${i18n.resolvedLanguage}/branches`);

      const branchesPopulate = branchesRes.data?.branches?.map((branch) => {
        let zones = branch?.deliveryzones?.sort((a,b)=>{ return a.fees - b.fees; }).map((zone) => {
          // console.log('fees',zone);
          return { fees: zone.fees, zid: zone.id, latLng: new google.maps.LatLng(+zone.lat, +zone.lng), radius: +zone.rad, fillColor: `${zone.color}aa`, strokeColor: `${zone.color}aa` }
        })
        return { id: branch.id, title: branch.name, zones: zones, delivery_enabled: branch.delivery };
      });
      setBranches(branchesRes.data?.branches);
      // console.log('branches', branchesPopulate);
      setBranchesMarkers(branchesPopulate);


    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);



  const initMap = async () => {
    try {

      setShowField(true);
      const google = await loader.load();
      const options = {
        componentRestrictions: { country: "eg" },
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
      };

      const geocoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(addressEl, options);
      const map = new google.maps.Map(mapEl, {
        zoom: 10,
        mapTypeControl: false,
        fullscreenControl: false,
        panControl: false,
        streetViewControl: false,
        zoomControl: false
      });
      autocomplete.bindTo("bounds", map);
      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true
      });

      geocoder.geocode({ 'address': props.city }, function (results, status) {
        if (status === 'OK' && results[0].geometry.location.lat()) {
          startLatLng = results[0].geometry.location;
          // console.log('results[0].geometry.location', results[0].geometry.location);
          map.setCenter(results[0].geometry.location);
          marker.setPosition(results[0]?.geometry?.location);
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });

      google.maps.event.addListener(marker, "dragend", function (e) {

        inZone = [];
        setSelectedBranch(undefined);
        startLatLng = e.latLng;

        for (const branch of branchesMarkers) {

          if (branch['delivery_enabled'] === 1) {
            for (const zone of branch['zones']) {
              if (google.maps.geometry.spherical.computeDistanceBetween(startLatLng, zone['latLng']) <= zone['radius']) {
                inZone.push({ ...zone, branch: branch['title'], id: branch['id'] });
                // setSelectedBranch(inZone[inZone.length - 1 || 0]);
                const smallestValue = Math.min(...inZone.map(zone=>zone.fees));
                const selZone = inZone.find(zone=>zone.fees === smallestValue);
                // console.log('dragend',selZone);
                setSelectedBranch(selZone);
                //  console.log('branch in zone dragend', zone);

              } else {
                // console.log('=> is NOT in zoneArea');
                // inZone = {};
              }
            }
          }


        };

        geocoder
          .geocode({ location: marker.getPosition() })
          .then((response) => {

            let posGeo = response['results'][0]['formatted_address'];
            setAddress(posGeo);
            // console.log('posGeo', posGeo);
            setGeoCodedAddress(posGeo);
          }).catch((err) => {
            console.log(err);
          })
      });


      autocomplete.addListener("place_changed", () => {

        // marker.setVisible(false);

        // console.log('place changed');
        setSelectedBranch(undefined);
        inZone = [];
        const place = autocomplete.getPlace();
        // console.log('place autocomplete', place?.geometry?.location);

        geocoder
          .geocode({ location: place.geometry.location })
          .then((response) => {
            // console.log(response['results']);
            let posGeo = response['results'][0]['formatted_address'];
            setAddress(posGeo);
            setGeoCodedAddress(posGeo);
          }).catch((err) => {
            console.log('geocode error', err);
          })

        for (const branch of branchesMarkers) {
          // console.log('branch', branch);

          if (branch.delivery_enabled === 1) {
            for (const zone of branch['zones']) {
              if (google.maps.geometry.spherical.computeDistanceBetween(place.geometry.location, zone['latLng']) <= zone['radius']) {
                // console.log('=> is in zoneArea');
                inZone.push({ ...zone, branch: branch['title'], id: branch['id'] });
                const smallestValue = Math.min(...inZone.map(zone=>zone.fees));
                const selZone = inZone.find(zone=>zone.fees === smallestValue);
                // console.log('search autocomplete',selZone);
                setSelectedBranch(selZone);
                // setSelectedBranch( inZone[inZone.length - 1]);
                // console.log('branch in zone', zone);

                // console.log('branch in zone', inZone[0]);

              } else {
                // console.log('=> is NOT in zoneArea');
                // inZone = {};
              }
              // locationsArr.push({loc:google.maps.geometry.spherical.computeDistanceBetween(startLatLng, zone['latLng']) <= zone['radius']});
            }
          }
        };

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for place: '" + place.name + "'");
          return;
        }


        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        marker.setPosition(place.geometry.location);

      });


      // console.log('branchesMarkers', branchesMarkers);

      for (let i = 0; i < branchesMarkers.length; i++) {
        // console.log('branchesMarkers[i]', branchesMarkers[i]);
        if (branchesMarkers[i]['delivery_enabled'] === 1) {
          for (let ii = 0; ii < branchesMarkers[i].zones.length; ii++) {

            branchesMarkers[i]['zones'][ii]['bound'] = new google.maps.Circle({
              strokeColor: branchesMarkers[i]['zones'][ii]['strokeColor'],
              strokeOpacity: 0.5,
              strokeWeight: 2,
              fillColor: branchesMarkers[i]['zones'][ii]['fillColor'],
              fillOpacity: 0.2,
              map: map,
              zIndex: -ii,
              center: branchesMarkers[i]['zones'][ii]['latLng'],
              radius: branchesMarkers[i]['zones'][ii]['radius']
            });

            if (startLatLng) {

              if (google.maps.geometry.spherical.computeDistanceBetween(startLatLng, branchesMarkers[i]['zones'][ii]['latLng']) <= branchesMarkers[i]['zones'][ii]['radius']) {
                // console.log('=> is in zoneArea');
                inZone.push({ ...branchesMarkers[i]['zones'][ii], branch: branchesMarkers[i]['title'], id: branchesMarkers[i]['id'] });
                // console.log('branch in zone', inZone[0]);
            
                const smallestValue = Math.min(...inZone.map(zone=>zone.fees));
                const selZone = inZone.find(zone=>zone.fees === smallestValue);
                setSelectedBranch(selZone);
                // console.log('default',selZone);
                // setSelectedBranch(inZone[inZone.length -1 || 0]);
                // console.log('branch in zone branchesMarkers', branchesMarkers[i]['zones'][ii]);

                // setSelectedBranch(inZone[0]);
              } else {
                // inZone = {};
                // console.log('=> is NOT in zoneArea');
                // inZone = {};
              }
            };
          };
        };
      }

      addressEl.addEventListener('keydown', function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      });

    } catch (error) {
      console.log(error);
    }

  };

  const clearCartAndhandleSelectedZone = () => {
    cartCtx.clear();
    handleSelectedZone();
  }

  const handleSelectedZone = () => {
    const selectedBranchInitialObject = branches.find(branch => branch.id === selectedBranch?.id);
    checkoutCtx.setDeliveryType('delivery');
    if (checkoutCtx.pendingCheckout) {
      navigate('/checkout-confirm');
      checkoutCtx.setPendingCheckout(false);
    } else {
      if(sessionStorage.getItem('rdx_product')){
        navigate(-3);
        sessionStorage.removeItem('rdx_product');
      }else{
        navigate('/products');
      }
      
    }

    if (selectedBranchInitialObject && geoCodedaddress) {
      authCtx.saveBranch(selectedBranchInitialObject);
      checkoutCtx.saveDeliveryAddress({ address: geoCodedaddress, fees: selectedBranch?.fees, id: selectedBranch?.id });
      checkoutCtx.setZoneCovered(true);
    } else {
      checkoutCtx.saveDeliveryAddress({ address: geoCodedaddress, fees: 0, id: selectedBranch?.id });
      checkoutCtx.setZoneCovered(false);
    };
  };

  const handleSelectedZonePress = () => {
    const selectedBranchInitialObject = branches.find(branch => branch.id === selectedBranch?.id);

    if (cartCtx.items.length > 0 && authCtx.branch?.id !== selectedBranchInitialObject?.id) {
      setBranchChangeDialogOpen(true);
    } else {
      handleSelectedZone();
      if (config.developmentMode) {
      console.log('handleSelectedZone',selectedBranch);
      }
    };
  }



  const keyDownPreventHandler = (e) => {
    if (e.keyCode === 13) { //13 is the key code for Enter
      e.preventDefault()
      //Here you can even write the logic to select the value from the drop down or something.
    }
  };

  useEffect(() => {
    initMap();
  }, [branches]);

  return (
    <>
      <FormControl className={`${Styles.FormControl} ${i18n.resolvedLanguage === 'ar' && Styles.FormControl_rtl}`} style={{ display: showField ? 'block' : 'none' }}>

        <Input
          size='medium'
          inputProps={{ style: { padding: '10px' } }}
          sx={{ width: '100%' }}
          value={address || ''}
          onChange={(e) => { setAddress(e.target.value) }}
          id="address"
          type="text" fullWidth placeholder={t("Search for your location")}
          onKeyDown={keyDownPreventHandler}
          startAdornment={
            <InputAdornment position="start" sx={{ ml: 0, mr: 0 }}>
              <IconButton onClick={() => {
                setAddress('');
              }}>
                <Close />

              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="start" sx={{ mr: '16px', ml: 0 }}>
              <img src={SearchIcon} />
            </InputAdornment>
          }
        />
      </FormControl>

      <div id="map" style={{ height: '100%' }}></div>
      <Box className={Styles.DeliveryLocation} sx={{ backgroundColor: '#fff', }}>
        {geoCodedaddress && <Box sx={{ pb: '16px', pt: '5px' }}>
          {/* <Typography sx={{ p: '0 0 15px 0' }} fontWeight="bold">{t('Delivery location')}</Typography> */}
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: '16px' }}>
              <img src={LocationIcon} />
            </Box>
            <Box>
              <Typography variant='body2'>{geoCodedaddress}</Typography>
            </Box>
          </Box>

        </Box>}

        <Button variant="contained" disabled={!geoCodedaddress} fullWidth disableElevation size="large" onClick={handleSelectedZonePress} >{t('Save Location')}</Button>
      </Box>
      <BranchChangeDialog active={branchChangeDialogOpened} onAgree={clearCartAndhandleSelectedZone} onDismiss={() => { setBranchChangeDialogOpen(false) }} />
    </>

  )

};

export default React.memo(GoogleMapsLocation);