
import { Link } from 'react-router-dom';
import React from "react";
import { Pagination, Autoplay } from "swiper";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import Styles from './Banner.module.css';
import config from '../../../../config/config';
import { useContext } from 'react';
import { ThemeOptionsContext } from '../../../../theme/ThemeProvider';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Banner = (props) => {

    const themeCtx = useContext(ThemeOptionsContext);
    return <>
        {props.items.length > 0 && <Box sx={{ p: (themeCtx.appearance.banner_style === 'boxed') ? '20px 16px 16px 16px' : '0px' }}>
            {props.items.length === 1 && <Box>
                {props.items[0].type === 'category' && <Link to={`/categories/${props.items[0].category}`}>
                    {props.items[0].image && <LazyLoadImage effect='blur' src={props.items[0].image.includes('demo') ? config.url + '/' + props.items[0].image : config.assets + '/' + props.items[0].image} alt={props.items[0].title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'auto' }} />}                </Link>}
                {props.items[0].type === 'item' && <Link to={`/products/${props.items[0].id}`}>
                    {props.items[0].image && <LazyLoadImage effect='blur' src={props.items[0].image.includes('demo') ? config.url + '/' + props.items[0].image : config.assets + '/' + props.items[0].image} alt={props.items[0].title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'auto' }} />}
                </Link>}
                {props.items[0].type === 'link' && <a href={props.items[0].link} >

                    {props.items[0].image && <LazyLoadImage effect='blur' src={props.items[0].image.includes('demo') ? config.url + '/' + props.items[0].image : config.assets + '/' + props.items[0].image} alt={props.items[0].title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'auto' }} />}
                </a>}

                {props.items[0].type === 'none' &&
                    <>
                   
                        {props.items[0].image && <LazyLoadImage effect='blur' src={props.items[0]?.image.includes('demo') ? config.url + '/' + props.items[0].image : config.assets + '/' + props.items[0].image} alt={props.items[0].title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'auto' }} />}
                    </>
                }
            </Box>}
            {props.items.length > 1 && <Swiper
                pagination={{ clickable: true }}
                className={`${Styles.Banner}`}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                allowTouchMove={true}
                spaceBetween={5}
                slidesPerView={1}
                loop={true}
                modules={[Pagination, Autoplay]}

            >
                {
                    props.items.map((item, i) => {

                        return (<SwiperSlide key={i} className={Styles.Banner__item}>
                            {item.type === 'category' && <Link to={`/categories/${item.category}`}>
                                {item.image && <LazyLoadImage effect='blur' src={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} alt={item.title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'220px' }} />}
                                {/* {!item.image && <img src={BannerImage} alt={item.title} className={Styles["cat__item--img"]} style={{borderRadius:(themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px'}} />} */}
                            </Link>}
                            {item.type === 'item' && <Link to={`/products/${item.id}`}>
                                {item.image && <LazyLoadImage effect='blur' src={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} alt={item.title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'220px' }} />}
                                {/* {!item.image && <img src={BannerImage} alt={item.title} className={Styles["cat__item--img"]} style={{borderRadius:(themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px'}} />} */}
                            </Link>}
                            {item.type === 'link' && <a href={item.link} >

                                {item.image && <LazyLoadImage effect='blur' src={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} alt={item.title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'220px' }} />}
                                {/* {!item.image && <img src={BannerImage} alt={item.title} className={Styles["cat__item--img"]} style={{borderRadius:(themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px'}} />} */}
                            </a>}

                            {item.type === 'none' &&
                                <>
                                    {item.image && <LazyLoadImage effect='blur' src={item.image.includes('demo') ? config.url + '/' + item.image : config.assets + '/' + item.image} alt={item.title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px',maxWidth:'100%',width:'100%',objectFit:'cover', height:'220px' }} />}
                                    {/* {!item.image && <img src={BannerImage} alt={item.title} className={Styles["cat__item--img"]} style={{ borderRadius: (themeCtx.appearance.banner_style === 'boxed') ? '10px' : '0px' }} />} */}
                                </>
                            }
                        </SwiperSlide>)
                    })
                }

            </Swiper>}
        </Box>}
    </>;
};

export default Banner;