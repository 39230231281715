import { IconButton, Typography, AppBar, Toolbar } from "@mui/material";
import Styles from '../ProductDetailsPage.module.css';
import CloseSharp from '@mui/icons-material/CloseSharp';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductsDetailsAppBar = ({appBarScrolling,title})=>{
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    return (
        <AppBar
        sx={{ backgroundColor: appBarScrolling ? '#fff' : 'transparent' }} className={`${Styles.appBar} ${i18n.resolvedLanguage === 'ar' && Styles.appBar_rtl}`} elevation={appBarScrolling ? 1 : 0}>
        <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 5, top: appBarScrolling ? 0 : 3, position: "relative", zIndex: 100, backgroundColor: '#fff' }}
                onClick={()=>navigate(-1) } ><CloseSharp htmlColor={"#000"} /></IconButton>
            <Typography variant="h6" color="#000" component="div" sx={{ flexGrow: 1, opacity: appBarScrolling ? 1 : 0 }}>
                {title}
            </Typography>
        </Toolbar>
    </AppBar>
    )
};

export default ProductsDetailsAppBar;