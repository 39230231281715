import { Box, Divider } from '@mui/material';
import CustomAppBar from '../../components/CustomAppBar/CustomAppBar';
import ItemsList from './components/ItemsList/ItemsList';
import OrderTotals from './components/OrderTotals/OrderTotals';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosConfig from '../../config/axios';
import { useTranslation } from 'react-i18next';
import OrderMeta from './components/OrderMeta/OrderMeta';
import useOrder from '../../hooks/useOrder';
import SuspenseLoader from '../../components/SuspenseLoader/SuspenseLoader';


const OrderDetailsPage = () => {
    const { id } = useParams();
    const { i18n } = useTranslation();
    const orderQuery = useOrder(id);
    return (<Box id="OrderDetailsPage" sx={{ minHeight: '100%' }}>
        <CustomAppBar hasBack backDark title={`${!orderQuery.isLoading ? orderQuery.data?.inv_num : ''}`} notif />
        {orderQuery.isLoading && <SuspenseLoader />}
        {!orderQuery.isLoading && <ItemsList items={orderQuery.data?.items} />}
        {!orderQuery.isLoading && <Box sx={{ p: '16px' }}>
            <OrderMeta order={orderQuery.data} />
            <Divider />
            <OrderTotals totals={orderQuery.data} />
        </Box>}
    </Box>)
};

export default OrderDetailsPage;