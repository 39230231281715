import { useContext, useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, OutlinedInput, Button } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import RegisterationContext from '../../../store/register-context';
import AuthWrapper from '../../../components/AuthWrapper/AuthWrapper';
import axiosInstance from '../../../config/axios';
import { useTranslation } from 'react-i18next';

const VerifyPage = () => {
    const registerationContext = useContext(RegisterationContext);
    const { state } = useLocation();

    const navigate = useNavigate();
    const [otp, setOtp] = useState();
    const [invalidOtp, setInvalidOtp] = useState(false);

    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState(59);
    const { t } = useTranslation();

    const onCodeChange = (e) => {
        setInvalidOtp(false);
        const val = e.target.value;
        setOtp(val);
    }

    const sendOtpHandler = async () => {

        if (registerationContext.phone) {
            console.log('phone',registerationContext.phone);
            setTimerMinutes('00');
            setTimerSeconds(59);
            try {
                await axiosInstance.post(`/customer/send-otp-msg?phone=0${registerationContext.phone}`, {});
            } catch (error) {
                console.log('sendOtpHandler', error);
              
            }
        };
    };

    const startTimerHandler = () => {
        setTimerMinutes('00');
        setTimerSeconds(59);
        var timer = setInterval(() => {

            setTimerMinutes(prev => {
                return '00'
            });

            setTimerSeconds(prev => {
                if (prev === 0) {

                    // console.log('timer finished');
                    clearInterval(timer);
                    return 0
                } else {
                    return prev - 1;
                }
            });
        }, 1000);
    }

    useEffect(() => {
        startTimerHandler();

    }, []);

    useEffect(() => {
       if(!registerationContext.phone){
        navigate(-1);
       }
            
    }, [registerationContext.phone]);


    const registerSubmitHandler = async () => {
    
        if (otp.length === 4) {
            try {
                const otpSubmitRes = await axiosInstance.post(`/customer/confirm-otp-msg?opt_code=${otp}&phone=0${registerationContext.phone}`, {});
                registerationContext.setAccessToken(otpSubmitRes.data?.token);
                if (state?.type === 'forgotPassword') {
                    // console.log('type',state?.type);
                    // registerationContext.setAccessToken(otpSubmitRes.data?.token);
                    navigate('/reset-password');
                } else {
                    navigate('/register');
                };

            } catch (error) {
                console.log('registerSubmitHandler', error);
                setInvalidOtp(true);
            }
        } else {
            setOtp(undefined);
        };
    }

    return (<AuthWrapper>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t('Confirm Phone')}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}>{t('Check SMS sent to your phone')} <strong>{'0'+registerationContext.phone}</strong></Typography>

        <Box sx={{ marginBottom: '15px' }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-Code"  color='dark'>{t('Code')}</InputLabel>
                <OutlinedInput
                 color='dark'
                    id="outlined-adornment-Code"
                    label="Code"
                    onChange={onCodeChange}
                />
            </FormControl>
            {invalidOtp && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Invalid OTP')}</Typography>}

            {otp?.length === 0 && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('OTP is Required')}</Typography>}
            <Box sx={{ pt: '16px' }}>
                {timerSeconds > 0 && <Typography variant="body2" textAlign="center"> {t('You can resend new code after')} {timerMinutes}:{timerSeconds >= 10 ? timerSeconds : '0' + timerSeconds}  {t('seconds')}</Typography>}
                {timerSeconds === 0 && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center' }}><Typography>{t('Didnt receive OTP?')}</Typography><Button onClick={sendOtpHandler}>{t('Resend')}</Button></Box>}
            </Box>
        </Box>
        <Button variant='contained' disabled={!otp || otp?.length < 4 || otp?.length > 4 || invalidOtp} color='primary' onClick={registerSubmitHandler} fullWidth>{t('Verify')}</Button>
    </AuthWrapper>)
};

export default VerifyPage