import { useContext} from "react"
import { useTranslation } from "react-i18next";
import AuthContext from "../store/auth-context";
import { useQuery } from "@tanstack/react-query";
import { fetchOrder } from "../providers/orders";

const useOrder = (id) => {
    const { i18n } = useTranslation();
    const authCtx = useContext(AuthContext);
    const orderQuery = useQuery({
        queryKey: ['orders',id],
        queryFn: () => fetchOrder(id,authCtx, i18n)
    });
    return orderQuery;
}

export default useOrder;