import { Card, Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProductNote = ({ onChange }) => {
    const { t } = useTranslation();
    
    return (
        <Card elevation={0} sx={{ mx: '15px', padding: '15px' }}>
            <Box >
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-notes">{t('Notes')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-notes"
                        type="text"
                        label={t("Notes")}
                        placeholder={t("write what you need extra")}
                        onChange={onChange}
                    />
                </FormControl>
            </Box>
        </Card>
    )
};

export default ProductNote;