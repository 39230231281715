const OrderingIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0198 20H3.36638C2.25736 20 1.38619 19.2904 1.38619 18.3871V12.2581H2.17827V18.3871C2.17827 18.9354 2.69321 19.3548 3.36638 19.3548H18.0198C18.693 19.3548 19.2079 18.9354 19.2079 18.3871V1.6129C19.2079 1.06461 18.693 0.645161 18.0198 0.645161H3.36638C2.69323 0.645161 2.17827 1.06459 2.17827 1.6129V7.09677H1.38619V1.6129C1.38619 0.709585 2.25736 0 3.36638 0H18.0198C19.1288 0 20 0.709585 20 1.6129V18.3871C20 19.2904 19.1288 20 18.0198 20Z"
        fill={props.color}
      />
      <path
        d="M14.9309 2.90323H6.4557C5.86164 2.90323 5.34668 2.4838 5.34668 1.99991V0.322582H6.13876V1.99991C6.13876 2.12898 6.29722 2.25805 6.45568 2.25805H14.9309C15.0894 2.25805 15.2478 2.12898 15.2478 1.99991L15.2476 0.322582H16.0397V1.99991C16.0397 2.48378 15.525 2.90323 14.9309 2.90323Z"
        fill={props.color}
      />
      <path
        d="M18.4157 18.0645H2.97021V13.5484H3.76229V17.4194H17.6236V2.25807H15.6434V1.61291H18.4157V18.0645Z"
        fill={props.color}
      />
      <path
        d="M3.76229 8.06452H2.97021V1.61291H5.74248V2.25807H3.76229V8.06452Z"
        fill={props.color}
      />
      <path
        d="M7.88122 14.2903L5.34652 13.6128L0 9.25793L1.66325 7.90318L7.00977 12.258L7.88122 14.2903ZM5.78211 13.0644L6.61373 13.2902L6.33659 12.5806L1.66323 8.77403L1.10873 9.22568L5.78211 13.0644Z"
        fill={props.color}
      />
      <path
        d="M2.19015 8.77926L2.75017 9.23541L1.91012 9.91965L1.3501 9.4635L2.19015 8.77926Z"
        fill={props.color}
      />
      <path
        d="M6.14105 11.9728L6.70107 12.429L5.58102 13.3413L5.021 12.8851L6.14105 11.9728Z"
        fill={props.color}
      />
      <path
        d="M5.74268 4.83871H15.2476V5.48387H5.74268V4.83871Z"
        fill={props.color}
      />
      <path
        d="M5.74268 6.45161H12.8714V7.09677H5.74268V6.45161Z"
        fill={props.color}
      />
      <path
        d="M5.74268 8.06452H14.8516V8.70968H5.74268V8.06452Z"
        fill={props.color}
      />
    </svg>
  );
};

export default OrderingIcon;
