import { Box, Typography,useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LogoImg from '../../assets/images/logo.png';
import InfoContext from '../../store/info-context';
import Styles from './Brand.module.css';
import config from '../../config/config';
import { getTranslation } from '../../utils/helper';
import { ThemeOptionsContext } from '../../theme/ThemeProvider';

const Brand = (props) => {

    const theme = useTheme();
    const { info } = useContext(InfoContext);
    const { t, i18n } = useTranslation();
    const appearanceCtx = useContext(ThemeOptionsContext)
    


    return <div className="brand">
        <Box className={Styles.brand__logo}  >
            
          { info?.logo &&  <img src={config.assets + info.logo} alt="" className={`${Styles['brand__logo--img']} ${props.sm && Styles['brand__logo--img_sm']}`}   />}
          { !info?.logo &&  <Box sx={{backgroundColor:appearanceCtx.appearance?.intro_text_color?appearanceCtx.appearance?.intro_text_color:'#000'}} className={`${Styles['brand__logo--placeholder']} ${props.sm && Styles['brand__logo--img_sm']}`}> <Typography>{getTranslation(info,i18n.resolvedLanguage)?.name}</Typography></Box>}
        </Box>
{ !info?.welcome_msg  && <Typography textAlign="center" variant="h5" component="h5" sx={{color:appearanceCtx.appearance?.intro_text_color}}>{t('Welcome to')} {getTranslation(info,i18n.resolvedLanguage)?.name}</Typography>}
{ info?.welcome_msg  && <Typography textAlign="center" variant="h5" component="h5" sx={{color:appearanceCtx.appearance?.intro_text_color}}>{info?.welcome_msg}</Typography>}

    </div>
};

export default Brand;