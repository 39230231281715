import { useNavigate, Link } from "react-router-dom";
import BottomTabs from "../../components/BottomTabs/BottomTabs.js";
import { Box, ButtonBase, Typography, Grid, Divider } from '@mui/material';
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import arrowRightIcon from '../../assets/images/arrowRightIcon.png';
import arrowLeftIcon from '../../assets/images/arrowLeftIcon.png';
import AuthContext from "../../store/auth-context.js";
import { useContext, useEffect, useState } from 'react';
import LanguageDialog from "../../components/LanguageDialog/LanguageDialog.js";
import { useTranslation } from "react-i18next";
import LogoutDialog from "./components/LogoutDialog.js";
import UserAvatar from "../../components/UserAvatar/UserAvatar.js";
import InfoContext from "../../store/info-context.js";
import useOffers from "../../hooks/useOffers.js";

const MenuPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [islanguageDialogOpen, setLanguageDialogOpen] = useState(false);
  const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const authCtx = useContext(AuthContext);
  const infoCtx = useContext(InfoContext);
  const offersQuery = useOffers();

  const menuFootPages = [{
    label: 'About Us',
    image: 'aboutIcon',
    action: () => navigate('/about')
  },
  {
    label: authCtx.isLoggedIn ? 'Log out' : 'Login',
    image: 'LogoutIcon',
    action: () => {
      authCtx.isLoggedIn ? setLogoutDialogOpen(true) : navigate('/login');
    }
  }];

  const hasSocialMedia = () => {
    const socialmedia = infoCtx.info?.socialmedia && Object.keys(infoCtx.info?.socialmedia).filter(social => infoCtx.info?.socialmedia[social] !== null);
    return socialmedia?.length > 0;
  }

  return (
    <>
      <Box className="MenuPage">
        <CustomAppBar isFloating={true} notif={true} />
        <UserAvatar />
        <Box>
          {offersQuery.data?.items?.length > 0 && <ButtonBase sx={{ width: '100%', px: '16px', py: '15px' }} onClick={() => navigate('/categories/offers')}>
            <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={1}><img src={require(`../../assets/images/offersIcon.png`)} /></Grid>
              <Grid item xs={10}><Typography textAlign="left">{t('Offers')}</Typography></Grid>
              <Grid item xs={1}><img src={i18n.resolvedLanguage === 'en' ? arrowRightIcon : arrowLeftIcon} /></Grid>
            </Grid>
          </ButtonBase>}


          {(infoCtx.info?.working_hours || infoCtx.info?.address || infoCtx.info?.phone || hasSocialMedia()) && <ButtonBase sx={{ width: '100%', px: '16px', py: '15px' }} onClick={() => navigate('/contacts')}>
            <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={1}><img src={require(`../../assets/images/contactsIcon.png`)} /></Grid>
              <Grid item xs={10}><Typography textAlign="left">{t('Contacts')}</Typography></Grid>
              <Grid item xs={1}><img src={i18n.resolvedLanguage === 'en' ? arrowRightIcon : arrowLeftIcon} /></Grid>
            </Grid>
          </ButtonBase>}


          {infoCtx.languages?.length > 1 && <ButtonBase sx={{ width: '100%', px: '16px', py: '15px' }} onClick={() => setLanguageDialogOpen(true)}>
            <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={1}><img src={require(`../../assets/images/languageIcon.png`)} /></Grid>
              <Grid item xs={10}><Typography textAlign="left">{t('Language')} {t(i18n.resolvedLanguage.toUpperCase())}</Typography></Grid>
              <Grid item xs={1}><img src={i18n.resolvedLanguage === 'en' ? arrowRightIcon : arrowLeftIcon} /></Grid>
            </Grid>
          </ButtonBase>}

        </Box>
        <Divider sx={{ mx: '16px', my: '10px' }} />
        <Box sx={{ mb: '10px' }}>
          {menuFootPages.map((page, i) => {
            return <ButtonBase key={i} sx={{ width: '100%', px: '16px', py: '15px' }} onClick={page.action}>
              <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={1}><img src={require(`../../assets/images/${page.image}.png`)} /></Grid>
                <Grid item xs={10}><Typography textAlign="left">{t(page.label)}</Typography></Grid>
                <Grid item xs={1}><img src={i18n.resolvedLanguage === 'en' ? arrowRightIcon : arrowLeftIcon} /></Grid>
              </Grid>
            </ButtonBase>
          })}


        </Box>
      </Box>
      <LogoutDialog open={isLogoutDialogOpen} onClose={() => { setLogoutDialogOpen(false) }} />
      <LanguageDialog languages={infoCtx.languages} open={islanguageDialogOpen} onClose={() => { setLanguageDialogOpen(false); }} />
      <BottomTabs />
    </>
  );
};

export default MenuPage;
