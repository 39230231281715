import { useState, useEffect, SetStateAction, useContext } from "react";
import BottomTabs from "../../components/BottomTabs/BottomTabs.js";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { Box } from '@mui/material';
import ProductsList from "../../components/ProductsList/ProductsList";
import CategoriesBar from "./CategoriesBar/CategoriesBar";
import { useTranslation } from 'react-i18next';
import axiosConfig from "../../config/axios";
import config from '../../config/config';
import ProductsListSkeleton from "../../components/skeleton/ProductsListSkeleton/ProductsListSkeleton.js";
import CategoriesBarSkeleton from "../../components/skeleton/CategoriesBarSkeleton/CategoriesBarSkeleton.js";
import AuthContext from "../../store/auth-context";
import useCategories from "../../hooks/useCategories";
import useOffers from "../../hooks/useOffers.js";
import { useParams } from "react-router-dom";
import InfoContext from "../../store/info-context.js";
import BannerSkeleton from "../../components/skeleton/BannerSkeleton/BannerSkeleton.js";
import useCategory from "../../hooks/useCategory.js";


const CategoriesPage = () => {
    const params = useParams();
    const [defaultCat, setDefaultCat] = useState(params?.id);
    const categoriesQuery = useCategories();
    const { t, i18n } = useTranslation();
    const infoCtx = useContext(InfoContext);
    const offersQuery = useOffers();
    const categoryQuery = useCategory(params?.id ? params?.id : categoriesQuery.data?.categories[0].id );

    const offersSelectionHandler = () => {
        setDefaultCat(undefined);
    }

    return <>
        <div id="CategoriesPage">
            <CustomAppBar enableGps gpsTop hasBack={true} notif={true} backDark backRoute='/products' title={infoCtx.info?.account_active === false ? 'Categories' : undefined} />
            {!categoriesQuery.isLoading && <CategoriesBar hasOffers={offersQuery.data?.items.length > 0} items={categoriesQuery.data?.categories}  selectedCategory={params?.id ? params?.id : categoriesQuery.data?.categories[0].id} onOffersSelect={offersSelectionHandler} />}
            {categoriesQuery.isLoading && <CategoriesBarSkeleton />}

            {params?.id !== 'offers' && (!categoryQuery.isLoading && categoryQuery.data?.category?.image) && <Box sx={{ marginLeft: '15px', marginRight: '15px' }}><img src={categoryQuery.data?.category?.image.includes('demo') ? config.url + '/' + categoryQuery.data?.category?.image : config.assets + '/' + categoryQuery.data?.category?.image} alt="" style={{ borderRadius: '15px', width: '100%', height: '200px', objectFit: 'cover' }} /></Box>}
            {params?.id !== 'offers' && categoryQuery.isLoading && <BannerSkeleton />}


            {params?.id !== 'offers' && <Box sx={{ marginBottom: '60px', marginTop: '16px' }}>
                {!categoryQuery.isLoading && <ProductsList items={categoryQuery.data?.items} />}
                {categoryQuery.isLoading && <ProductsListSkeleton length={4} />}
            </Box>}
            {params?.id === 'offers' && <Box sx={{ marginBottom: '60px', marginTop: '16px' }}>
                {!offersQuery.isLoading && <ProductsList items={offersQuery.data?.items} />}
                {offersQuery.isLoading && <ProductsListSkeleton length={4} />}
            </Box>}
        </div>
        <BottomTabs />
    </>
};

export default CategoriesPage;