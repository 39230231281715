import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import {  IconButton, Box, AppBar, Toolbar } from "@mui/material";
import Brand from "../Brand/Brand";
import { useTranslation } from "react-i18next";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { ThemeOptionsContext } from "../../theme/ThemeProvider";

const AuthWrapper = ({ children}) => {
    const appearanceCtx = useContext(ThemeOptionsContext);
    const { t, i18n } = useTranslation();

    return <Box style={{padding:'30px'}} className=" d-flex flex-column justify-content-center h-100">
            {appearanceCtx.appearance.enable_intro === '1' && <AppBar color="transparent" elevation={0}>
                <Toolbar>
                    <IconButton component={Link} to="/" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={"#000"} />}
                        {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={"#000"} />}
                    </IconButton>
                </Toolbar>
            </AppBar>}
            <Brand showLabel={true} sm={true} />
            <Box  sx={{ mt: '60px' }}>
                {children}
            </Box>
        </Box>
};

export default AuthWrapper;