let url = window.location.protocol + '//' + window.location.hostname;
const developmentMode = false;

if (developmentMode) {
  // if (localStorage.getItem('test_domain')) {
  //   testRestaurant = localStorage.getItem('test_domain');
  // };
  url = `https://homefood.gromenu.com`;
};

const config = {
  url,
  developmentMode,
  assets: `${url}/tenancy/assets/`
};

export default Object.freeze(config);